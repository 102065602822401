import React, { useState } from "react";
import { MDBIcon } from "mdbreact";
import { Link } from "react-router-dom";

import FeedHeader from "components/feed/feedHeader";
import FeedTags from "components/feed/controllers/feedTags";
import FeedView from "components/feed/feedView";

import { useFeed, FeedSourceType } from "contexts/feedContext";
import { useSettings } from "contexts/settingsContext";
import { useUser } from "contexts/userContext";
import { useMobileWebview } from "contexts/mobileWebviewContext";

const Feed = () => {
  const { orgsTree } = useUser();
  const { feedConfig, setFeedConfig } = useFeed();
  const { isMobileWebview } = useMobileWebview();

  const { feedContainerWidth } = useSettings();

  const isConnect =
    feedConfig.source === FeedSourceType.CONNECT_SINGLE_TOPIC ||
    feedConfig.source === FeedSourceType.CONNECT_ORGANIZATION;
  const hasManifest = orgsTree.userHasManifest;
  const feedViewWidth = window.innerWidth > 992 ? `${feedContainerWidth}px` : "100%";

  return (
    <div className={`feed ${isConnect ? "feed-connect" : ""} ${isMobileWebview ? "mobile-webview" : ""}`}>
      <FeedHeader />

      <div className="feed-container">
        <div className="feed-view" style={{ width: `${feedViewWidth}` }}>
          <FeedTags />
          {hasManifest && (
            <Link
              className="add-topic-button"
              onClick={() => {
                setFeedConfig({
                  ...feedConfig,
                  source: undefined,
                  subject: undefined,
                });
              }}
              to="/addtopic"
            >
              <MDBIcon icon="plus-square" />
            </Link>
          )}
        </div>
        <FeedView />
      </div>
    </div>
  );
};

export default Feed;
