import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { MDBInput } from "mdbreact";
import AutocompleteAssignedTo from "components/forms/controllers/autocompleteAssignedTo";
import TagsList from "components/forms/controllers/tagsList";
import DateTimePicker from "components/forms/controllers/dateTimePicker";
import Select from "components/forms/controllers/select";
import { MDBIcon } from "mdbreact";
import { useUser } from "contexts/userContext";
import LoadingIndicator from "components/loadingIndicator";

const AddAssignmentForm = ({ id, title }) => {
  const { user, tokenAccessApi } = useUser();

  const slugJson = user.slugJson;
  if (slugJson.FieldList) {
    slugJson.FieldList = user.slugJson.FieldList.sort((a, b) => a.sort.localeCompare(b.sort));
  }

  const hasSlugJson = slugJson.FieldList !== undefined && slugJson.FieldList.length > 0;

  const [formFields, setFormFields] = useState([]);
  const [values, setValues] = useState([]);
  const history = useHistory();
  const [slugArr, setSlugArr] = useState(hasSlugJson ? new Array(slugJson.FieldList.length) : []);
  let slugTopic = "";
  if (hasSlugJson) {
    slugJson.FieldList.map((field) => {
      if (field.sort === "1") {
        let charControl = field.charcontrol === "" ? " " : field.charcontrol;
        slugTopic = title.replace(/ /g, charControl) + field.delimiter;
      }
    });
  }
  const [slug, setSlug] = useState(slugTopic);
  const [addAssignmentFromTopic, setAddAssignmentFromTopic] = useState(window.location.search.split("aaa=")[1]);
  const [firstCheckDropdownValues, setFirstCheckDropdownValues] = useState(true);

  const [loadingForm, setLoadingForm] = useState(false);
  const [errorLoading, setErrorLoading] = useState(false);
  const [errorSaving, setErrorSaving] = useState(false);
  const error = errorLoading || errorSaving;

  let searchParams = window.location.search;
  let aTitle = "";
  let aAssignmentId = "";
  if (searchParams.indexOf("aTitle") > -1) {
    aTitle = searchParams.split("aTitle=")[1].split("&aAssignmentId=")[0];
    aAssignmentId = searchParams.split("aTitle=")[1].split("&aAssignmentId=")[1];
  }

  const handleSavedFormRedirect = (url) => {
    history.push(url);
  };

  const checkOneOptionDropdownsForSlug = (valuesJson) => {
    valuesJson.map((value, index) => {
      if (value.type === "dropdown" && value.config?.fixedValues.length === 1) {
        onChangeFixedDropdowns(value.config.fixedValues[0], value.id, index);
      }
    });
  };

  const getForm = useCallback(async () => {
    setLoadingForm(true);
    const url = `${process.env.REACT_APP_TOPICS_API_LC_BASE_URL}/${id}/organization/${user.curr_organization_id}/assignment-form`;

    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${tokenAccessApi}`,
        },
      });
      setFormFields(response.data);
      setValues(response.data);
      setLoadingForm(false);
    } catch (error) {
      console.error(error);
      setErrorLoading(true);
      setLoadingForm(false);
    } finally {
      setLoadingForm(false);
    }
  }, []);

  const saveForm = useCallback(async () => {
    setLoadingForm(true);
    const url = `${process.env.REACT_APP_TOPICS_API_LC_BASE_URL}/${id}/assignment`;

    try {
      const response = await axios.post(url, values, {
        headers: {
          Authorization: `Bearer ${tokenAccessApi}`,
        },
      });
      let topicId = id;
      let topicTitle = title;
      handleSavedFormRedirect(
        `/addassignment/${topicId}/${encodeURIComponent(topicTitle)}?aTitle=${response.data.title}&aAssignmentId=${
          response.data.id
        }`,
      );
      setLoadingForm(false);
    } catch (error) {
      console.error(error);
      setErrorSaving(true);
      setLoadingForm(false);
    }
  }, [values]);

  useEffect(() => {
    getForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (values.length > 0 && firstCheckDropdownValues) {
      checkOneOptionDropdownsForSlug(values);
      setFirstCheckDropdownValues(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  function onChangeFixedDropdowns(value, id, index) {
    const newValues = [...values];
    newValues[index]["value"] = value;
    setValues(newValues);
    buildSlug(value, id);
  }

  function onChange(ev) {
    const { value } = ev.target;
    const index = ev.target.getAttribute("index");
    const newValues = [...values];
    newValues[index]["value"] = value;
    setValues(newValues);
    buildSlug(ev.target.value, ev.target.id);
  }

  function onChangeAlt(index, value, id) {
    const newValues = [...values];
    newValues[index]["value"] = value;
    setValues(newValues);
    buildSlug(JSON.stringify(value), id);
  }

  const slugFields = [];

  if (hasSlugJson) {
    slugJson.FieldList.map((field) => {
      slugFields[parseInt(field.sort) - 1] = field.fieldID;
      // slugFields.push(field.fieldID)
    });
  }

  function buildSlug(val, id) {
    slugFields.map((field, i) => {
      if (field === id) {
        let charControl = slugJson.FieldList[i].charcontrol === "" ? " " : slugJson.FieldList[i].charcontrol;
        let newArr = [...slugArr];
        newArr[0] = slugTopic;
        newArr[i] = val.replace(/ /g, charControl) + slugJson.FieldList[i].delimiter;
        values.map((field, j) => {
          if (field.id === slugJson.SlugNameField) {
            const newValues = [...values];
            newValues[j]["value"] = newArr.join("");
            setSlugArr(newArr);
            setSlug(newArr.join(""));
            setValues(newValues);
          }
        });
      }
    });
  }

  function onSubmit(ev) {
    ev.preventDefault();
    setAddAssignmentFromTopic(false);
    saveForm();
  }

  function cancel() {
    history.push("/");
  }

  function closeAlert() {
    setAddAssignmentFromTopic(false);
  }

  let i = 0;

  return (
    <div className="form-container">
      {loadingForm && <LoadingIndicator />}

      {!loadingForm && addAssignmentFromTopic && (
        <div className="success-message">
          <span>
            Success: Topic <strong>{title}</strong> has been created, would you like to add another assignment? or{" "}
            <a href={`/topic/${id}`}>go to the topic created?</a>
          </span>{" "}
          <button className="close-alert-btn" onClick={closeAlert}>
            <MDBIcon icon="times" />
          </button>
        </div>
      )}

      {aTitle !== "" && !loadingForm && !error && (
        <div className="success-message">
          <span>
            You can add another assignment or{" "}
            <a href={`/topic/${id}/${aAssignmentId}`}>go to the one you just created</a>
          </span>
        </div>
      )}

      {!loadingForm && errorLoading && (
        <div className="error-message">
          There has been an error loading the form. <br />
          Please try refreshing the page or reach out to <a href="mailto:support@latakoo.com">support@latakoo.com</a>.
        </div>
      )}

      {!loadingForm && errorSaving && (
        <div className="error-message">
          There has been an error saving the form. <br />
          Please reach out to <a href="mailto:support@latakoo.com">support@latakoo.com</a>.
        </div>
      )}

      {formFields.length > 0 && !loadingForm && !error && (
        <form className="add-assignment-form" onSubmit={onSubmit}>
          <div className="form-row form-row-buttons form-top-buttons">
            <button type="submit" className="btn-primary">
              Add Assignment
            </button>
          </div>

          <div className="form-fields">
            <div key={i++} className={`order0 form-title`}>
              New Assignment for Topic: {title}
            </div>

            <h2 className="order4">Assignment Fields</h2>

            {formFields.map((formField, index) => {
              let inform = formField.config.inform === "0" ? "topic-field" : "assignment-field";
              const nameHtmlEscaped = new DOMParser().parseFromString(formField.name, "text/html").documentElement
                .textContent;

              switch (formField.type) {
                case "assignment":
                  // 	return <div key={i++} className={`order1 ${inform}`}><MDBInput type="text" label={nameHtmlEscaped} id={formField.id} name={nameHtmlEscaped} required={`required`} onChange={onChange} index={index} /></div>;
                  if (hasSlugJson) {
                    return (
                      <div key={i++} className={`order1 ${inform}`}>
                        <MDBInput
                          type="text"
                          label={nameHtmlEscaped}
                          id={formField.id}
                          name={nameHtmlEscaped}
                          required={`required`}
                          onChange={onChange}
                          index={index}
                          value={slug}
                          disabled={hasSlugJson}
                        />
                      </div>
                    );
                  } else {
                    return (
                      <div key={i++} className={`order1 ${inform}`}>
                        <MDBInput
                          type="text"
                          label={nameHtmlEscaped}
                          id={formField.id}
                          name={nameHtmlEscaped}
                          required={`required`}
                          onChange={onChange}
                          index={index}
                        />
                      </div>
                    );
                  }

                case "person":
                  return (
                    <AutocompleteAssignedTo
                      id={formField.id}
                      className={`order7 ${inform}`}
                      key={i++}
                      onChangeAlt={onChangeAlt}
                      index={index}
                      value={formField.value}
                    />
                  );

                case "text":
                  return (
                    <div key={i++} className={`order2 ${inform}`}>
                      <MDBInput
                        type="text"
                        label={nameHtmlEscaped}
                        id={formField.id}
                        name={nameHtmlEscaped}
                        onChange={onChange}
                        index={index}
                      />
                    </div>
                  );

                case "memo":
                  return (
                    <div key={i++} className={`order2 ${inform}`}>
                      <MDBInput
                        type="textarea"
                        label={nameHtmlEscaped}
                        id={formField.id}
                        name={nameHtmlEscaped}
                        rows="2"
                        onChange={onChange}
                        index={index}
                      />
                    </div>
                  );

                case "date":
                  return (
                    <DateTimePicker
                      id={formField.id}
                      index={index}
                      onChangeAlt={onChangeAlt}
                      className={`form-row form-date-picker order2 ${inform}`}
                      label={"Date/Time"}
                      key={i++}
                    />
                  );

                case "dropdown":
                  if (nameHtmlEscaped === "Tags") {
                    return (
                      <TagsList
                        id={formField.id}
                        tags={formField.config.fixedValues}
                        className={`order2 ${inform}`}
                        key={i++}
                        index={index}
                        onChangeAlt={onChangeAlt}
                      />
                    );
                  } else {
                    return (
                      <Select
                        name={nameHtmlEscaped}
                        id={formField.id}
                        options={formField.config.fixedValues}
                        className={`order2 ${inform}`}
                        key={i++}
                        index={index}
                        onChange={onChange}
                      />
                    );
                  }

                default:
                  return (
                    <div key={i++} className={`order2 ${inform}`}>
                      <MDBInput
                        type="text"
                        label={nameHtmlEscaped}
                        id={formField.id}
                        name={nameHtmlEscaped}
                        onChange={onChange}
                        index={index}
                      />
                    </div>
                  );
              }
            })}
            <h2 className="order6">Standard Fields</h2>
          </div>

          <div className="form-row form-row-buttons">
            <button type="button" className="btn-secondary" onClick={cancel}>
              Cancel
            </button>
            <button type="submit" className="btn-primary">
              Add Assignment
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default AddAssignmentForm;
