import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { MDBInput } from "mdbreact";
import AutocompleteAssignedTo from "components/forms/controllers/autocompleteAssignedTo";
import TagsList from "components/forms/controllers/tagsList";
import DateTimePicker from "components/forms/controllers/dateTimePicker";
import Select from "components/forms/controllers/select";
import Cookies from "universal-cookie";
import { useUser } from "contexts/userContext";
import LoadingIndicator from "components/loadingIndicator";
import { dollarStringToCents } from "components/utils/priceString";
import DollarInput from "./controllers/dollarInput";
import HourDurationInput from "./controllers/hourDurationInput";
import { CONNECT_PURCHASE_CONFIG } from "constants/connect-purchase-config";

const AddTopicForm = () => {
  const { user, tokenAccessApi } = useUser();

  const slugJson = user.slugJson;
  if (slugJson.FieldList) {
    slugJson.FieldList = user.slugJson.FieldList.sort((a, b) => a.sort.localeCompare(b.sort));
  }

  const hasSlugJson = slugJson.FieldList !== undefined && slugJson.FieldList.length > 0;

  const [formFields, setFormFields] = useState([]);
  const [values, setValues] = useState([]);
  const [slug, setSlug] = useState("");
  const [slugArr, setSlugArr] = useState(hasSlugJson ? new Array(slugJson.FieldList.length) : []);
  const history = useHistory();
  const [firstCheckDropdownValues, setFirstCheckDropdownValues] = useState(true);

  let tz = "";
  const cookies = new Cookies();
  if (cookies.get("tzCookie") === undefined) {
    cookies.set("tzCookie", Intl.DateTimeFormat().resolvedOptions().timeZone, { path: "/" });
    tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  } else {
    tz = cookies.get("tzCookie");
  }

  const [loadingForm, setLoadingForm] = useState(false);
  const [errorLoading, setErrorLoading] = useState(false);
  const [errorSaving, setErrorSaving] = useState(false);
  const error = errorLoading || errorSaving;

  //
  // purchase option stuff
  //

  // load license options from the backend
  const [licenseOptions, setLicenseOptions] = useState(null);
  useEffect(() => {
    (async () => {
      const url = `${process.env.REACT_APP_TOPICS_API_LC_BASE_URL}/organization/${user.curr_organization_id}/licenses`;
      try {
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${tokenAccessApi}`,
          },
        });
        const licenseOptions = response.data.licenses;
        if (licenseOptions && licenseOptions.length > 0) {
          setLicenseOptions(licenseOptions.map((option) => ({ value: option.id, label: option.name })));
        }
      } catch (err) {
        // TODO do more with this?
        console.error(err);
      }
    })();
  }, []);

  const usePurchaseOptions = licenseOptions && licenseOptions.length > 0; // TODO there's probably a better way of doing this

  const [purchaseOptions, setPurchaseOptions] = useState([]);

  const addPurchaseOption = () => {
    if (!licenseOptions || licenseOptions.length === 0) {
      alert("Unable to add purchase option, no available licenses"); // TODO raise error modal or something like that?
      return;
    }
    setPurchaseOptions([
      ...purchaseOptions,
      {
        key: (purchaseOptions.at(-1)?.key ?? 0) + 1,
        name: "",
        description: "",
        price: 0,
        duration: 0,
        license_id: licenseOptions?.[0]?.value ?? 0,
      },
    ]);
  };
  // just hardcoding these for now as a quick hack to get this out the door
  const defaultPurchaseOptions = CONNECT_PURCHASE_CONFIG[user.curr_organization_id]?.defaultPurchaseOptions;
  const setPurchaseOptionsToDefault = () => {
    // just in case
    if (!defaultPurchaseOptions) {
      setPurchaseOptions([]);
      return;
    }

    setPurchaseOptions(
      defaultPurchaseOptions.map((option, i) => ({
        key: i,
        ...option,
      })),
    );
  };

  const updatePurchaseOption = (index, field, value) => {
    const newPurchaseOptions = [...purchaseOptions];
    newPurchaseOptions[index][field] = value;
    setPurchaseOptions(newPurchaseOptions);
  };

  //
  // end purchase option stuff
  //

  const handleSavedFormRedirect = (url) => {
    history.push(url);
  };

  const checkOneOptionDropdownsForSlug = (valuesJson) => {
    valuesJson.map((value, index) => {
      if (value.type === "dropdown" && value.config?.fixedValues.length === 1) {
        onChangeFixedDropdowns(value.config.fixedValues[0], value.id, index);
      }
    });
  };

  const getForm = useCallback(async () => {
    setLoadingForm(true);
    const url = `${process.env.REACT_APP_TOPICS_API_LC_BASE_URL}/organization/${user.curr_organization_id}/form`;

    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${tokenAccessApi}`,
        },
      });
      setFormFields(response.data);
      setValues([...response.data]);
      setLoadingForm(false);
    } catch (error) {
      console.error(error);
      setErrorLoading(true);
    } finally {
      setLoadingForm(false);
    }
  }, []);

  const saveForm = useCallback(async () => {
    // handle purchase options as a special case
    if (usePurchaseOptions && purchaseOptions.length) {
      // validate & reformat purchase options to the shape & datatypes that the backend expects
      // everything needs to have a name, price (in integer number of cents), and license_id
      // duration needs to be mapped from hours to seconds -- the backend expects seconds, it's just easier to keep it in hours in the UI state
      const formattedPurchaseOptions = [];
      for (let i = 0; i < purchaseOptions.length; i++) {
        const option = purchaseOptions[i];
        option.license_id = parseInt(option.license_id.toString()); // license_id might have been converted from int to string by the <select> component
        if (!option.name) {
          // TODO have a better way of delivering this information... even just a modal component would be better
          alert("Please fill out all required fields for each purchase option");
          return;
        }
        formattedPurchaseOptions.push({
          name: option.name,
          description: option.description || "",
          price: dollarStringToCents(option.price),
          ...(option.duration ? { duration: option.duration * 60 * 60 } : {}), // hours to seconds
          license_id: option.license_id,
        });
      }
      values.push({
        purchaseOptions: formattedPurchaseOptions,
      });
    }

    setLoadingForm(true);
    const url = process.env.REACT_APP_TOPICS_API_LC_BASE_URL;

    try {
      const response = await axios.post(url, values, {
        headers: {
          Authorization: `Bearer ${tokenAccessApi}`,
        },
      });
      let topicId = response.data.id;
      let topicTitle = response.data.title;
      handleSavedFormRedirect(`/addassignment/${topicId}/${encodeURIComponent(topicTitle)}?&aaa=true`);
    } catch (error) {
      console.error(error);
      setErrorSaving(true);
      setLoadingForm(false);
    }
  }, [values, purchaseOptions, usePurchaseOptions]);

  useEffect(() => {
    getForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (values.length > 0 && firstCheckDropdownValues) {
      checkOneOptionDropdownsForSlug(values);
      setFirstCheckDropdownValues(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  function onChangeFixedDropdowns(value, id, index) {
    const newValues = [...values];
    newValues[index]["value"] = value;
    setValues(newValues);
    buildSlug(value, id);
  }

  function onChange(ev) {
    const { value } = ev.target;
    const index = ev.target.getAttribute("index");
    const newValues = [...values];
    newValues[index]["value"] = value;
    setValues(newValues);
    buildSlug(ev.target.value, ev.target.id);
  }

  function onChangeAlt(index, value, id) {
    const newValues = [...values];
    newValues[index]["value"] = value;
    setValues(newValues);
    buildSlug(JSON.stringify(value), id);
  }

  function onSubmit(ev) {
    ev.preventDefault();
    saveForm();
  }

  function cancel() {
    history.push("/");
  }

  const slugFields = [];

  if (hasSlugJson) {
    slugJson.FieldList.map((field) => {
      slugFields[parseInt(field.sort) - 1] = field.fieldID;
      // slugFields.push(field.fieldID)
    });
  }

  function buildSlug(val, id) {
    slugFields.map((field, i) => {
      if (field === id) {
        let charControl = slugJson.FieldList[i].charcontrol === "" ? " " : slugJson.FieldList[i].charcontrol;
        let newArr = [...slugArr];
        newArr[i] = val.replace(/ /g, charControl) + slugJson.FieldList[i].delimiter;

        values.map((field, j) => {
          if (field.id === slugJson.SlugNameField) {
            const newValues = [...values];
            newValues[j]["value"] = newArr.join("");
            setSlugArr(newArr);
            setSlug(newArr.join(""));
            setValues(newValues);
          }
        });
      }
    });
  }

  let i = 0;

  return (
    <div className="form-container">
      {loadingForm && <LoadingIndicator />}

      {!loadingForm && errorLoading && (
        <div className="error-message">
          There has been an error loading the form. <br />
          Please try refreshing the page or reach out to <a href="mailto:support@latakoo.com">support@latakoo.com</a>.
        </div>
      )}

      {!loadingForm && errorSaving && (
        <div className="error-message">
          There has been an error saving the form. <br />
          Please reach out to <a href="mailto:support@latakoo.com">support@latakoo.com</a>.
        </div>
      )}

      {!loadingForm && formFields.length > 0 && !error && (
        <form className="add-topic-form" onSubmit={onSubmit}>
          <div className="form-row form-row-buttons form-top-buttons">
            <button type="submit" className="btn-primary">
              Create
            </button>
          </div>

          <div className="form-fields">
            <div className="form-title">New Topic for {user.curr_organization_name}</div>
            <h2 className="order0">Topic Fields</h2>

            <h2 className="order4">Assignment Fields</h2>

            {formFields.map((formField, index) => {
              let inform = formField.config.inform === "0" ? "topic-field" : "assignment-field";
              const nameHtmlEscaped = new DOMParser().parseFromString(formField.name, "text/html").documentElement
                .textContent;

              switch (formField.type) {
                case "topic":
                  return (
                    <div key={i++} className={`order1 ${inform}`}>
                      <MDBInput
                        type="text"
                        label={nameHtmlEscaped}
                        id={formField.id}
                        name={nameHtmlEscaped}
                        required={`required`}
                        onChange={onChange}
                        index={index}
                      />
                    </div>
                  );

                case "assignment":
                  if (hasSlugJson) {
                    return (
                      <div key={i++} className={`order1 ${inform}`}>
                        <MDBInput
                          type="text"
                          label={nameHtmlEscaped}
                          id={formField.id}
                          name={nameHtmlEscaped}
                          required={`required`}
                          onChange={onChange}
                          index={index}
                          value={slug}
                          disabled={hasSlugJson}
                        />
                      </div>
                    );
                  } else {
                    return (
                      <div key={i++} className={`order1 ${inform}`}>
                        <MDBInput
                          type="text"
                          label={nameHtmlEscaped}
                          id={formField.id}
                          name={nameHtmlEscaped}
                          required={`required`}
                          onChange={onChange}
                          index={index}
                        />
                      </div>
                    );
                  }

                case "person":
                  return (
                    <AutocompleteAssignedTo
                      id={formField.id}
                      className={`order2 ${inform}`}
                      key={i++}
                      onChangeAlt={onChangeAlt}
                      index={index}
                      value={formField.value}
                    />
                  );

                case "text":
                  return (
                    <div key={i++} className={`order2 ${inform}`}>
                      <MDBInput
                        type="text"
                        label={nameHtmlEscaped}
                        id={formField.id}
                        name={nameHtmlEscaped}
                        onChange={onChange}
                        index={index}
                      />
                    </div>
                  );

                case "memo":
                  return (
                    <div key={i++} className={`order2 ${inform}`}>
                      <MDBInput
                        type="textarea"
                        label={nameHtmlEscaped}
                        id={formField.id}
                        name={nameHtmlEscaped}
                        rows="2"
                        onChange={onChange}
                        index={index}
                      />
                    </div>
                  );

                case "date":
                  return (
                    <DateTimePicker
                      id={formField.id}
                      index={index}
                      onChangeAlt={onChangeAlt}
                      className={`form-row form-date-picker order2 ${inform}`}
                      label={nameHtmlEscaped}
                      key={i++}
                    />
                  );

                case "dropdown":
                  if (nameHtmlEscaped === "Tags") {
                    return (
                      <TagsList
                        id={formField.id}
                        tags={formField.config.fixedValues}
                        className={`order2 ${inform}`}
                        key={i++}
                        index={index}
                        onChangeAlt={onChangeAlt}
                      />
                    );
                  } else {
                    return (
                      <Select
                        name={nameHtmlEscaped}
                        id={formField.id}
                        options={formField.config.fixedValues}
                        className={`order2 ${inform}`}
                        key={i++}
                        index={index}
                        onChange={onChange}
                      />
                    );
                  }

                default:
                  return (
                    <div key={i++} className={`order3 ${inform}`}>
                      <MDBInput
                        type="text"
                        label={nameHtmlEscaped}
                        id={formField.id}
                        name={nameHtmlEscaped}
                        index={index}
                      />
                    </div>
                  );
              }
            })}
          </div>

          {usePurchaseOptions && (
            <>
              <h2>Purchase Options</h2>
              {purchaseOptions.length === 0 ? (
                <p>Default (free indefinite standard license)</p>
              ) : (
                purchaseOptions.map((purchaseOption, i) => (
                  <div className="form-row" key={purchaseOption.key}>
                    <MDBInput
                      type="text"
                      label="Name"
                      id={"purchaseOptionName" + purchaseOption.key}
                      name={"Name"}
                      required={true}
                      value={purchaseOption.name}
                      onChange={(e) => updatePurchaseOption(i, "name", e.target.value)}
                    />
                    <MDBInput
                      type="textarea"
                      label="Description"
                      id={"purchaseOptionName" + purchaseOption.key}
                      name={"Description"}
                      required={false}
                      value={purchaseOption.description}
                      onChange={(e) => updatePurchaseOption(i, "description", e.target.value)}
                    />
                    <Select
                      name={"License"}
                      id={"license"}
                      options={licenseOptions}
                      required={true}
                      value={purchaseOption.license_id}
                      onChange={(e) => updatePurchaseOption(i, "license_id", e.target.value)}
                    />
                    <HourDurationInput
                      label="License duration"
                      id="licenseDuration"
                      name="licenseDuration"
                      value={purchaseOption.duration}
                      onUpdateValue={(value) => updatePurchaseOption(i, "duration", value)}
                      emptyValue="Indefinite"
                    />
                    <DollarInput
                      label={"Price"}
                      id={"price"}
                      name={"price"}
                      value={purchaseOption.price}
                      onUpdateValue={(value) => updatePurchaseOption(i, "price", value)}
                    />
                  </div>
                ))
              )}
              <div className="form-row">
                <button type="button" className="btn-primary" onClick={addPurchaseOption}>
                  Add Option
                </button>
                {defaultPurchaseOptions && (
                  <button
                    type="button"
                    className="btn-primary"
                    onClick={setPurchaseOptionsToDefault}
                    // please forgive the manual style, giving this a CSS class would be more trouble than it's worth I think, this works
                    style={{ marginLeft: "1rem" }}
                  >
                    Set to Defaults
                  </button>
                )}
              </div>
            </>
          )}

          <div className="form-row form-row-buttons">
            <button type="button" className="btn-secondary" onClick={cancel}>
              Cancel
            </button>
            <button type="submit" className="btn-primary">
              Create
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default AddTopicForm;
