import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import { MDBInput } from "mdbreact";
import AutocompleteAssignedTo from "components/forms/controllers/autocompleteAssignedTo";
import TagsList from "components/forms/controllers/tagsList";
import DateTimePicker from "components/forms/controllers/dateTimePicker";
import Select from "components/forms/controllers/select";
import { useUser } from "contexts/userContext";
import LoadingIndicator from "components/loadingIndicator";
import { FeedSourceType, useFeed } from "contexts/feedContext";

const EditAssignmentForm = ({ topicId, assignmentId, topicTitle }) => {
  const { user, tokenAccessApi } = useUser();
  const { feedConfig, setFeedConfig, resetFeedData } = useFeed();

  const slugJson = user.slugJson;
  if (slugJson.FieldList) {
    slugJson.FieldList = user.slugJson.FieldList.sort((a, b) => a.sort.localeCompare(b.sort));
  }

  const hasSlugJson = slugJson.FieldList !== undefined && slugJson.FieldList.length > 0;

  const [formFields, setFormFields] = useState([]);
  const [values, setValues] = useState(formFields);
  const history = useHistory();
  const slugFields = [];
  const [loadingForm, setLoadingForm] = useState(false);
  const [savedForm, setSavedForm] = useState(false);

  if (hasSlugJson) {
    slugJson.FieldList.map((field) => {
      slugFields.push(field.fieldID);
    });
  }

  let initialSlugArr = hasSlugJson ? new Array(slugJson.FieldList.length) : [];
  const [slugArr, setSlugArr] = useState([]);
  const [slug, setSlug] = useState("");

  function populateInitialArr(values) {
    if (hasSlugJson) {
      let charControl = slugJson.FieldList[0].charcontrol === "" ? " " : slugJson.FieldList[0].charcontrol;
      initialSlugArr[0] = topicTitle.replace(/ /g, charControl) + slugJson.FieldList[0].delimiter;
      if (slugFields.length > 0) {
        slugFields.map((slugField, i) => {
          values.map((value) => {
            if (value.id === slugField && value.value.length !== 0) {
              initialSlugArr[i] =
                value.value.toString().replace(/ /g, slugJson.FieldList[i].charcontrol) +
                slugJson.FieldList[i].delimiter;
            }
          });
        });
      }

      setSlugArr(initialSlugArr);
      setSlug(initialSlugArr.join(""));
    }
  }

  const getForm = useCallback(async () => {
    setLoadingForm(true);
    const url = `${process.env.REACT_APP_TOPICS_API_LC_BASE_URL}/${topicId}/assignments/${assignmentId}/form`;

    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${tokenAccessApi}`,
        },
      });
      setFormFields(response.data);
      setValues(response.data);
      populateInitialArr(response.data);
      setLoadingForm(false);
    } catch (error) {
      console.error(error);
      setLoadingForm(false);
      // setHasError(true);
    } finally {
      setLoadingForm(false);
    }
  }, []);

  const saveForm = useCallback(async () => {
    setLoadingForm(true);
    const url = `${process.env.REACT_APP_TOPICS_API_LC_BASE_URL}/${topicId}/assignments/${assignmentId}`;

    try {
      const response = await axios.put(url, values, {
        headers: {
          Authorization: `Bearer ${tokenAccessApi}`,
        },
      });
      setSavedForm(true);
    } catch (error) {
      console.error(error);
      // setHasError(true);
    } finally {
      setLoadingForm(false);
    }
  }, [values]);

  useEffect(() => {
    getForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignmentId]);

  function onChange(ev) {
    const { value } = ev.target;
    const index = ev.target.getAttribute("index");
    const newValues = [...values];
    newValues[index]["value"] = value;
    setValues(newValues);
    buildSlug(ev.target.value, ev.target.id);
  }

  function onChangeAlt(index, value, id) {
    const newValues = [...values];
    newValues[index]["value"] = value;
    setValues(newValues);
    buildSlug(JSON.stringify(value), id);
  }

  function buildSlug(val, id) {
    slugFields.map((field, i) => {
      if (field === id) {
        let charControl = slugJson.FieldList[i].charcontrol === "" ? " " : slugJson.FieldList[i].charcontrol;
        let newArr = [...slugArr];
        newArr[i] = val.replace(/ /g, charControl) + slugJson.FieldList[i].delimiter;
        values.map((field, j) => {
          if (field.id === slugJson.SlugNameField) {
            const newValues = [...values];
            newValues[j]["value"] = newArr.join("");
            setSlugArr(newArr);
            setSlug(newArr.join(""));
            setValues(newValues);
          }
        });
      }
    });
  }

  function onSubmit(ev) {
    ev.preventDefault();
    saveForm();
    // save({
    // 	data: values
    // });
  }

  function cancel() {
    history.push("/");
  }

  let i = 0;

  let assignmentName = values.filter((obj) => {
    return values.type === "assignment";
  });

  const singleViewPage = (source, subject = undefined) => {
    setFeedConfig({
      ...feedConfig,
      page: 1,
      source,
      subject,
    });
    resetFeedData();
  };

  return (
    <div className="form-container">
      {loadingForm && <LoadingIndicator />}

      {!loadingForm && savedForm && (
        <div className="success-message">
          <span>
            Success: Assignment <strong>{assignmentName.value}</strong> edited successfully
          </span>
          <span>
            Go to
            <Link
              to={`/topic/${topicId}/${assignmentId}`}
              onClick={() => singleViewPage(FeedSourceType.SINGLE_TOPIC, topicId)}
            >
              <span> assignment</span>
            </Link>
          </span>
        </div>
      )}

      {!loadingForm && formFields.length > 0 && (
        <form className="add-topic-form" onSubmit={onSubmit}>
          <div className="form-row form-row-buttons form-top-buttons">
            <button type="submit" className="btn-primary">
              Update Assignment
            </button>
          </div>

          <div className="form-fields">
            {formFields.map((formField) => {
              if (formField.type === "topic") {
                return (
                  <div key={i++} className={`order0 form-title`}>
                    {formField.value}
                  </div>
                );
              }
              if (formField.type === "assignment") {
                return (
                  <div key={i++} className={`order0 form-title assignment-title`}>
                    {formField.value}
                  </div>
                );
              }
            })}

            {formFields.map((formField, index) => {
              let inform = formField.config.inform === "0" ? "topic-field" : "assignment-field";
              const nameHtmlEscaped = new DOMParser().parseFromString(formField.name, "text/html").documentElement
                .textContent;

              switch (formField.type) {
                case "topic":
                  return (
                    <div key={i++} className={`order1 ${inform}`}>
                      <MDBInput
                        type="text"
                        label={nameHtmlEscaped}
                        id={formField.id}
                        name={nameHtmlEscaped}
                        onChange={onChange}
                        index={index}
                        value={formField.value}
                      />
                    </div>
                  );

                case "assignment":
                  if (hasSlugJson) {
                    return (
                      <div key={i++} className={`order1 ${inform}`}>
                        <MDBInput
                          type="text"
                          label={nameHtmlEscaped}
                          id={formField.id}
                          name={nameHtmlEscaped}
                          required={`required`}
                          onChange={onChange}
                          index={index}
                          value={slug}
                          disabled={hasSlugJson}
                        />
                      </div>
                    );
                  } else {
                    return (
                      <div key={i++} className={`order1 ${inform}`}>
                        <MDBInput
                          type="text"
                          label={nameHtmlEscaped}
                          id={formField.id}
                          name={nameHtmlEscaped}
                          onChange={onChange}
                          index={index}
                          value={formField.value}
                        />
                      </div>
                    );
                  }

                case "person":
                  return (
                    <AutocompleteAssignedTo
                      id={formField.id}
                      className={`order8 ${inform}`}
                      key={i++}
                      onChangeAlt={onChangeAlt}
                      index={index}
                      value={formField.value}
                    />
                  );

                case "text":
                  return (
                    <div key={i++} className={`order2 ${inform}`}>
                      <MDBInput
                        type="text"
                        label={nameHtmlEscaped}
                        id={formField.id}
                        name={nameHtmlEscaped}
                        onChange={onChange}
                        index={index}
                        value={formField.value}
                      />
                    </div>
                  );

                case "memo":
                  return (
                    <div key={i++} className={`order2 ${inform}`}>
                      <MDBInput
                        type="textarea"
                        label={nameHtmlEscaped}
                        id={formField.id}
                        name={nameHtmlEscaped}
                        rows="2"
                        onChange={onChange}
                        index={index}
                        value={formField.value}
                      />
                    </div>
                  );

                case "date":
                  return (
                    <DateTimePicker
                      id={formField.id}
                      index={index}
                      onChangeAlt={onChangeAlt}
                      className={`form-row form-date-picker order2 ${inform}`}
                      label={"Date/Time"}
                      value={`Mon Nov 30 2020 00:00:00 GMT-0300 (Brasilia Standard Time)`}
                      key={i++}
                    />
                  );

                case "dropdown":
                  if (nameHtmlEscaped === "Tags") {
                    return (
                      <TagsList
                        id={formField.id}
                        tags={formField.config.fixedValues}
                        className={`order2 ${inform}`}
                        key={i++}
                        index={index}
                        onChangeAlt={onChangeAlt}
                        value={formField.value}
                      />
                    );
                  } else {
                    return (
                      <Select
                        name={nameHtmlEscaped}
                        id={formField.id}
                        options={formField.config.fixedValues}
                        className={`order2 ${inform}`}
                        key={i++}
                        index={index}
                        onChange={onChange}
                        value={formField.value.length > 0 ? formField.value.toString() : ""}
                      />
                    );
                  }

                default:
                  return (
                    <div key={i++} className={`order3 ${inform}`}>
                      <MDBInput
                        type="text"
                        label={nameHtmlEscaped}
                        id={formField.id}
                        name={nameHtmlEscaped}
                        index={index}
                        value={formField.value}
                      />
                    </div>
                  );
              }
            })}
            <h2 className="order6">Standard Fields</h2>
          </div>
          <div className="form-row form-row-buttons">
            <button type="button" className="btn-secondary" onClick={cancel}>
              Cancel
            </button>
            <button type="submit" className="btn-primary">
              Update Assignment
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default EditAssignmentForm;
