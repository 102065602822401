import React from "react";
import { FeedSourceType, useFeed } from "contexts/feedContext";
import { useUser } from "contexts/userContext";
import { useMobileWebview } from "contexts/mobileWebviewContext";

const FeedHeader = () => {
  const { setFeedConfig, feedConfig, feedGeneralContent, feedLayout, resetFeedData, setFeedLayout } = useFeed();
  const { user, orgsTree } = useUser();
  const { isMobileWebview } = useMobileWebview();

  const organizations =
    feedConfig.source === FeedSourceType.CONNECT_ORGANIZATION ||
    feedConfig.source === FeedSourceType.CONNECT_SINGLE_TOPIC
      ? orgsTree.public_trees.children
      : user.org_tree.children;

  let feedTitle = "";

  function getOrgName(orgId) {
    const preorderTreeTraversal = (org) => {
      if (org.id.toString() === orgId.toString()) {
        return org;
      }

      return org.children.map(preorderTreeTraversal).find((element) => !!element);
    };

    const foundOrg = organizations.map(preorderTreeTraversal).find((element) => !!element);

    // console.log(organizations);
    if (!foundOrg) {
      return null;
    }

    return foundOrg.name;
  }

  if (feedConfig.source === FeedSourceType.USER) {
    feedTitle = "Personal Feed";
    // } else if (feedConfig.source === FeedSourceType.CONNECT_ORGANIZATION || feedConfig.source === FeedSourceType.CONNECT_SINGLE_TOPIC) {
    //   feedTitle = 'Content';
  } else {
    let orgName;

    switch (feedConfig.source) {
      case FeedSourceType.ORGANIZATION:
        orgName = getOrgName(feedConfig.subject);
        break;
      case FeedSourceType.TOP_ORGANIZATION:
        orgName = user.top_organization_name;
        break;
      case FeedSourceType.CONNECT_ORGANIZATION:
        orgName = "latakoo Connect"; //getOrgName(feedConfig.subject)
        break;
      case FeedSourceType.CONNECT_SINGLE_TOPIC:
        orgName = "latakoo Connect"; //getOrgName(feedConfig.subject)
        break;
      case FeedSourceType.SINGLE_TOPIC:
        orgName = getOrgName(feedConfig.subject);
        break;
      default:
        orgName = "";
    }

    orgName = orgName ? orgName.replace("Spectrum", "") : orgName;

    if (feedGeneralContent) {
      feedTitle = "All Content";

      if (orgName) {
        feedTitle += ` for ${orgName}`;
      }
    } else {
      if (orgName) {
        feedTitle = `${orgName} `;
      }

      // feedTitle += 'Assignments';
      feedTitle +=
        feedConfig.source === FeedSourceType.CONNECT_ORGANIZATION ||
        feedConfig.source === FeedSourceType.CONNECT_SINGLE_TOPIC
          ? "Content"
          : "Assignments";
    }
  }

  const handleFeedLayout = (chosenLayout) => {
    setFeedConfig({
      ...feedConfig,
      page: 1,
    });
    resetFeedData();
    setFeedLayout(chosenLayout);
  };

  const isConnect =
    feedConfig.source === FeedSourceType.CONNECT_SINGLE_TOPIC ||
    feedConfig.source === FeedSourceType.CONNECT_ORGANIZATION;

  return (
    <>
      {/* {isConnect &&
        <div className='feed_header-connect_warning'>You are viewing third-party content outside of your company's internal latakoo workflow.</div>
      } */}
      <div className="feed-header">
        <div className="feed-header_info">
          <h1>{feedTitle}</h1>
        </div>
      </div>
      {!isMobileWebview && (
        <div className="pilot_manifest_tabs">
          <ul>
            <li>
              <a href="//pilot.latakoo.com/">Pilot</a>
            </li>
            <li className="selected">
              <a href={`//manifest.latakoo.com/?ownerid=${user.id}`}>Connect | Manifest</a>
            </li>
          </ul>
        </div>
      )}
    </>
  );
};

export default FeedHeader;
