import React from "react";
import $ from "jquery";

// import VideoThumb from 'components/feed/feedCard/assetType/videoThumb';
import { latakooBirdNormal, latakooAudioIcon } from "../../../images/latakooImages.json";
import AssetTypes from "constants/asset-types";

const AssignmentHeaderThumbnail = ({ card, clickOpenArea }) => {
  const firstAsset = card.videos[0];
  const thumbnailAssetType = firstAsset?.asset_type;

  function onErrorLoadingImg(e) {
    e.target.src = "//latakoo.com/hif/images/latakoowhite.png";
    e.target.className = "latakoo-image";
  }

  function renderSwitch(type) {
    switch (type) {
      case AssetTypes.VIDEO:
        return (
          <img
            className={firstAsset.thumbnail_medium ? "" : "latakoo-image"}
            src={
              firstAsset.thumbnail_medium ? firstAsset.thumbnail_medium : "//latakoo.com/hif/images/latakoowhite.png"
            }
            alt={card.title}
            onError={onErrorLoadingImg}
          />
        );
      case AssetTypes.IMAGE:
        if (firstAsset.thumbnail_medium) {
          return <img src={firstAsset.thumbnail_medium} alt={card.title} onError={onErrorLoadingImg} />;
        } else {
          return <img className="latakoo-image" src={latakooBirdNormal} alt="latakoo Bird" />;
        }
      case AssetTypes.AUDIO:
        return <img src={latakooAudioIcon} alt="latakoo Audio Icon" />;
      case AssetTypes.HLS_STREAMING:
      case AssetTypes.STREAMING:
        return <div className="thumbnail-icon-ptz-stream"></div>;
      case AssetTypes.FOLDER:
        return <div className="thumbnail-icon-folder"></div>;
      case AssetTypes.GROUP:
        return (
          <div
            className={
              firstAsset.thumbnail_medium
                ? "group-thumb_image-container"
                : "group-thumb_image-container group-thumb_latakoo-image"
            }
          >
            <img
              className={firstAsset.thumbnail_medium ? "" : "latakoo-image"}
              src={
                firstAsset.thumbnail_medium ? firstAsset.thumbnail_medium : "//latakoo.com/hif/images/latakoowhite.png"
              }
              alt={card.title}
              onError={onErrorLoadingImg}
            />
          </div>
        );
      case AssetTypes.STITCH:
        return (
          <img
            className={firstAsset.thumbnail_medium ? "" : "latakoo-image"}
            src={
              firstAsset.thumbnail_medium ? firstAsset.thumbnail_medium : "//latakoo.com/hif/images/latakoowhite.png"
            }
            alt={card.title}
            onError={onErrorLoadingImg}
          />
        );
      case AssetTypes.OTHER:
        return <div className="thumbnail-icon-other"></div>;
      case undefined:
        return <img className="latakoo-image" src={latakooBirdNormal} alt="latakoo Bird" />;
      default:
        return (
          <img
            className={firstAsset.thumbnail_medium ? "" : "latakoo-image"}
            src={
              firstAsset.thumbnail_medium ? firstAsset.thumbnail_medium : "//latakoo.com/hif/images/latakoowhite.png"
            }
            alt={card.title}
            onError={onErrorLoadingImg}
          />
        );
    }
  }

  let assetTypeClassName = "";

  const getAssetTypeClassName = (thumbnailAssetType) => {
    switch (thumbnailAssetType) {
      case AssetTypes.VIDEO:
        if (firstAsset.thumbnail_medium) {
          assetTypeClassName = `${thumbnailAssetType} hasThumbnail`;
        } else {
          assetTypeClassName = `${thumbnailAssetType} noThumbnail`;
        }
        break;
      case AssetTypes.IMAGE:
        if (firstAsset.thumbnail_medium) {
          assetTypeClassName = `${thumbnailAssetType} hasThumbnail`;
        } else {
          assetTypeClassName = `${thumbnailAssetType} noThumbnail`;
        }
        break;
      case AssetTypes.GROUP:
        assetTypeClassName = `${thumbnailAssetType}`;
        break;
      case AssetTypes.STITCH:
        assetTypeClassName = `${thumbnailAssetType}`;
        break;
      case undefined:
        assetTypeClassName = `image noThumbnail`;
        break;
      default: // other - audio - streaming - folder
        assetTypeClassName = `${thumbnailAssetType} defaultIcon`;
        break;
    }
    return assetTypeClassName;
  };

  return (
    <div
      className={`card-header-video-thumb ${getAssetTypeClassName(thumbnailAssetType)}`}
      title={`Asset`}
      onClick={() => clickOpenArea(`assignmentId-${card.id}`)}
    >
      <div className={`video-thumb_asset-type`}>
        {thumbnailAssetType === AssetTypes.GROUP && card?.collection_info?.total && (
          <small>{card?.collection_info?.total} files</small>
        )}
        {renderSwitch(thumbnailAssetType)}
      </div>
    </div>
  );
};

export default AssignmentHeaderThumbnail;
